<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Filter -->
          <b-col
            cols="12"
          >
            <div
              class="d-flex align-items-center justify-content-start"
            >
              <label class="mr-1">Localidad: </label>
              <v-select
                v-model="localidad"
                label="name"
                :options="localidades"
                style="width: 460px;"
                @input="filterData"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        class="mx-2 mb-2"
        style="margin-top: -35px; position: relative;"
      >
        <b-row>
          <b-col cols="12">
            <div>
              <l-map
                :zoom="zoom"
                :center="center"
              >
                <l-tile-layer :url="url" />
                <l-marker
                  v-for="(poste,index) in items"
                  :key="(index+1)*5000"
                  :lat-lng="[poste.latitud !==''? poste.latitud: '', poste.longitud !==''? poste.longitud: '']"
                >
                  <l-icon>
                    <b-img
                      :src="require('@/assets/images/icons/recibida.png')"
                      height="25"
                    />
                  </l-icon>
                  <l-tooltip :content="`Número de Poste ${poste.numeroPoste}`" />
                  <l-popup :content="`Número de Poste ${poste.numeroPoste}`" />
                </l-marker>
              </l-map>
            </div>;
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import {
  LMap, LTileLayer, LMarker, LIcon, LTooltip, LPopup,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { getPostesCompany } from '@/utils/postes'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { getLocalidades } from '@/utils/localizacion'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
    LPopup,
    BCard,
    BRow,
    BCol,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catalogoName: 'Mapa de Puntos de Iluminiación',
      items: [],
      user: JSON.parse(localStorage.getItem('userData')),
      localidad: {},
      localidades: [],
      rows: [],
      // url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 15,
      center: [14.525952697272098, -90.5879227693148],
    }
  },
  async beforeMount() {
    try {
      this.localidades = await getLocalidades(undefined, this.user.company.municipio)
      this.items = []
      if (this.localidades === null) this.localidades = []
      if (this.localidades.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.localidad = this.localidades[0]
        await this.loadPostes(this.localidades[0])
      }
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.localidades = []
      this.items = []
    }
  },
  methods: {
    async loadPostes(filter) {
      if (filter === null) this.items = []
      else this.items = await getPostesCompany(this.user.company, filter.id)
      if (this.items === null) this.items = []
    },
    async filterData(value) {
      await this.loadPostes(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vue2leaflet-map {
  &.leaflet-container {
    height: 600px;
    margin-top: 50px;
  }
}
</style>
